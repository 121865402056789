/** @jsx jsx */
import { jsx } from 'theme-ui';
import Fab from '@material-ui/core/Fab';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import ContactForm from './contact-form';
import Subscribe, { readCookie } from './subscribe';

const Flavors = ({ data }) => (
  <div>
    <h1 className="large">Choose a flavor</h1>
    {/* <Subscribe initiallyOpen={false} /> */}
    <div
      sx={{
        display: `grid`,
        gridGap: [4, 4, 4, 4],
        gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`, `repeat(3, 1fr)`],
        h2: { gridColumn: `-1/1`, color: `white !important` },
        button: {
          height: '630px',
          fontSize: '27px',
          boxShadow: 'inset 0px 0px 10px 0px rgba(0, 0, 0, 0.35)',
          dropShadow: '1px 1px white',
        },
        padding: '2em 0',
      }}
    >
      <Fab variant="extended" id="quick" onClick={() => navigate('/pricing/coffee')}>
        <Img
          className="Flavor-image"
          fixed={data.image1.childImageSharp.fixed}
          alt="coffee ice cream cone"
        />
        <div>
          Coffee
          <br />
          <p
            sx={{
              padding: 0,
              margin: '0 0 -10px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            Starting At
          </p>
          <strong>
            $<span>979</span>
          </strong>
          <p
            sx={{
              fontSize: '16px',
              marginTop: '-10px',
            }}
          >
            Basic Website
          </p>
          <p
            sx={{
              paddingBottom: '20px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            For the starving artist.
          </p>
          <div className="Pricing-list-container">
            <ul className="Pricing-list">
              <li>
                <strong>Includes:</strong>
              </li>
              <li>Landing Page</li>
              <li>Contact Form</li>
              <li>Mobile-responsive</li>
              <li>Basic SEO Optimization</li>
              <li>Accessible (AA Standard)</li>
              <li>48 to 72 hours TAT</li>
              <li>Hosting included, first year of domain included</li>
              <li>Beautifully Designed</li>
              <li>Professionally Developed</li>
              <li>Complete Deployment</li>
              <li>100% Satisfaction Guaranteed</li>
            </ul>
          </div>
        </div>
      </Fab>
      <Fab
        variant="extended"
        id="integrated"
        disableRipple={true}
        disableFocusRipple={true}
        onClick={() => navigate('/pricing/mintchip')}
      >
        <Img
          className="Flavor-image"
          fixed={data.image2.childImageSharp.fixed}
          alt="mint chip ice cream cone"
        />
        <div>
          Mint Chip
          <br />
          <p
            sx={{
              padding: 0,
              margin: '0 0 -10px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            Starting At
          </p>
          <strong>
            $<span>1899</span>
          </strong>
          <p
            sx={{
              fontSize: '16px',
              marginTop: '-10px',
            }}
          >
            Startup Website
          </p>
          <p
            sx={{
              paddingBottom: '20px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            For the garage startup.
          </p>
          <div className="Pricing-list-container">
            <ul className="Pricing-list">
              <li>
                <strong>Includes:</strong>
              </li>
              <li>3-10 unique pages</li>
              <li>Contact Form</li>
              <li>Mobile-responsive</li>
              <li>Advanced SEO Optimization</li>
              <li>Accessible (AA Standard)</li>
              <li>2 Week TAT</li>
              <li>Hosting Included</li>
              <li>Beautifully Designed</li>
              <li>Professionally Developed</li>
              <li>Complete Deployment</li>
              <li>100% Satisfaction Guaranteed</li>
            </ul>
          </div>
        </div>
      </Fab>
      <Fab
        variant="extended"
        id="custom"
        disableRipple={true}
        disableFocusRipple={true}
        onClick={() => navigate('/pricing/caramelswirl')}
      >
        <Img
          className="Flavor-image"
          fixed={data.image3.childImageSharp.fixed}
          alt="caramel swirl ice cream cone"
        />
        <div>
          Caramel Swirl
          <br />
          <p
            sx={{
              padding: 0,
              margin: '0 0 -10px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            Starting At
          </p>
          <strong>
            $<span>4999</span>
          </strong>
          <p
            sx={{
              fontSize: '16px',
              marginTop: '-10px',
            }}
          >
            Professional Website
          </p>
          <p
            sx={{
              paddingBottom: '20px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            For the small business.
          </p>
          <div className="Pricing-list-container">
            <ul className="Pricing-list">
              <li>
                <strong>Includes:</strong>
              </li>
              <li>5-25 unique pages</li>
              <li>Contact Form</li>
              <li>CRM Integration</li>
              <li>Mobile-responsive</li>
              <li>Advanced SEO Optimization</li>
              <li>Accessible (AAA Standard)</li>
              <li>CMS Included</li>
              <li>Social Media Strategy Consultation</li>
              <li>Submission to Google for Indexing</li>
              <li>Search Console Submission</li>
              <li>Google My Business Optimization</li>
              <li>4 Week TAT</li>
              <li>Hosting Included</li>
              <li>Beautifully Designed</li>
              <li>Professionally Developed</li>
              <li>Complete Deployment</li>
              <li>100% Satisfaction Guaranteed</li>
            </ul>
          </div>
        </div>
      </Fab>

      <Fab
        variant="extended"
        id="peppermint"
        disableRipple={true}
        disableFocusRipple={true}
        onClick={() => navigate('/pricing/peppermint')}
      >
        <Img
          className="Flavor-image"
          fixed={data.image6.childImageSharp.fixed}
          alt="peppermint ice cream cone"
        />
        <div>
          Peppermint
          <br />
          <p
            sx={{
              padding: 0,
              margin: '0 0 -10px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            Starting At
          </p>
          <strong>$849</strong>
          <p
            sx={{
              fontSize: '16px',
              marginTop: '-10px',
            }}
          >
            Blog
          </p>
          <p
            sx={{
              paddingBottom: '20px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            For the writer.
          </p>
          <div className="Pricing-list-container">
            <ul className="Pricing-list">
              <li>
                <strong>Includes:</strong>
              </li>

              <li>Landing Page</li>
              <li>Unique Page URL For Each Blog Post</li>
              <li>CMS Included</li>
              <li>Mobile-responsive</li>
              <li>Advanced SEO Optimization</li>
              <li>Accessible (AA Standard)</li>
              <li>Contact Form</li>
              <li>48-72 hour TAT</li>
              <li>Hosting included</li>
              <li>Beautifully Designed</li>
              <li>Professionally Developed</li>
              <li>Complete Deployment</li>
              <li>100% Satisfaction Guaranteed</li>
            </ul>
          </div>
        </div>
      </Fab>
      <Fab
        variant="extended"
        id="onlineorder"
        disableRipple={true}
        disableFocusRipple={true}
        onClick={() => navigate('/pricing/rockyroad')}
      >
        <Img
          className="Flavor-image"
          fixed={data.image5.childImageSharp.fixed}
          alt="rocky road ice cream cone"
        />
        <div>
          Rocky Road
          <br />
          <p
            sx={{
              padding: 0,
              margin: '0 0 -10px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            Starting At
          </p>
          <strong>$3699</strong>
          <p
            sx={{
              fontSize: '16px',
              marginTop: '-10px',
            }}
          >
            Website + Online Ordering
          </p>
          <p
            sx={{
              paddingBottom: '20px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            For the restaurant or flower shop.
          </p>
          <div className="Pricing-list-container">
            <ul className="Pricing-list">
              <li>
                <strong>Includes:</strong>
              </li>
              <li>Landing Page</li>
              <li>Online Menu</li>
              <li>Online Ordering</li>
              <li>Receive Online Orders in Real-Time</li>
              <li>Accept Online Payments</li>
              <li>Payment Gateway Integration</li>
              <li>Option for Delivery (Optional)</li>
              <li>CMS Included</li>
              <li>Mobile-responsive</li>
              <li>Advanced SEO Optimization</li>
              <li>Accessible (AA Standard)</li>
              <li>Contact Form</li>
              <li>Hosting included</li>
              <li>Beautifully Designed</li>
              <li>Professionally Developed</li>
              <li>Complete Deployment</li>
              <li>100% Satisfaction Guaranteed</li>
            </ul>
          </div>
        </div>
      </Fab>
      <Fab
        variant="extended"
        id="ecommerce"
        disableRipple={true}
        disableFocusRipple={true}
        onClick={() => navigate('/pricing/blackcherry')}
      >
        <Img
          fixed={data.image4.childImageSharp.fixed}
          alt="black cherry ice cream cone"
          className="Flavor-image"
        />
        <div>
          Black Cherry
          <br />
          <p
            sx={{
              padding: 0,
              margin: '0 0 -10px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            Starting At
          </p>
          <strong>$2999</strong>
          <p
            sx={{
              fontSize: '16px',
              marginTop: '-10px',
            }}
          >
            E-Commerce Website
          </p>
          <p
            sx={{
              paddingBottom: '20px',
              fontSize: ['12px', '12px', '14px'],
            }}
          >
            For the basic online store.
          </p>
          <div className="Pricing-list-container">
            <ul className="Pricing-list">
              <li>
                <strong>Includes:</strong>
              </li>
              <li>Landing Page</li>
              <li>Online Store</li>
              <li>Accept Online Payments</li>
              <li>Payment Gateway Integration</li>
              <li>CMS Included</li>
              <li>Mobile-responsive</li>
              <li>Advanced SEO Optimization</li>
              <li>Accessible (AA Standard)</li>
              <li>Contact Form</li>
              <li>Hosting included</li>
              <li>Beautifully Designed</li>
              <li>Professionally Developed</li>
              <li>Complete Deployment</li>
              <li>100% Satisfaction Guaranteed</li>
            </ul>
          </div>
        </div>
      </Fab>
    </div>
    <br />
    <br />
    <br />
    <h3>Are you looking to develop a new software product from scratch?</h3>
    <Link
      sx={{
        background: '#6fffe9',
        padding: '10px 20px',
        borderRadius: '50px',
        color: 'rgba(0,0,0,0.87) !important',
      }}
      to="/mvp-development-company"
    >
      Check this out
    </Link>
    <br />
    <br />
    <br />
    <br />
    <ContactForm />
  </div>
);

export default Flavors;
